// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
	@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
	@import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
	background: none transparent !important;
	margin: 0 !important;
	padding-top: 0.5rem;
	code {
		overflow-y: auto;
		display: block;

		span {
			background: none transparent !important;
		}
	}
}

.json > pre {
	background: none #fff !important;
}

.example-code > .json {
	background: none #fff !important;
}

.symbol.symbol-45 > svg {
	width: 100%;
	max-width: 45px;
	height: 45px;
}

.react-bootstrap-table {
	overflow-x: auto;
}

.react-bootstrap-table {
	th {
		outline: none;

		&.sortable {
			.svg-icon-sort {
				opacity: 0;
			}

			&:hover {
				cursor: pointer;

				.svg-icon-sort {
					opacity: 1;
				}
			}
		}
	}

	.table.table-head-custom thead tr {
		.sortable-active {
			color: $primary !important;
		}
	}
}

.cursor-default {
	cursor: default !important;
}

.display-block {
	display: block;
}
.contentDash {
	font-size: 50px;
}
.contentDashB {
	font-size: 25px !important;
	color: #24284f !important;
}
.blueBorder {
	border: 3px solid #ebedf3 !important;
}
.default-label {
	color: white;
	font-weight: bold;
	background-color: #585757;
	padding: 5px 10px;
	border-radius: 10%;
	width: fit-content;
}
